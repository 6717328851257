
import { ref, computed, watch } from "vue";
import processors from "@/processors.json";
import { plan, email } from "@/refs/account";
import { showProModal } from "@/refs/modals";

import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";

// Import specific highlight.js theme CSS files
import "highlight.js/styles/github.css"; // Light theme
import "highlight.js/styles/github-dark.css"; // Dark theme

const inferenceUrl =
	process.env.VUE_APP_INFERENCE_URL || "https://inference.prodia.com";

export default {
	components: {
		highlightjs: hljsVuePlugin.component
	},
	setup: () => {
		const jobTypes = ([] as string[])
			.concat(...processors.map((p) => Object.keys(p.types)))
			.filter(
				(type) =>
					type.startsWith("inference.") &&
					(type.includes("txt2img") ||
						type.includes("img2img") ||
						(type.includes("mochi") && type.endsWith("v1")) ||
						type.includes("upscale"))
			);

		const selectedJobType = ref<(typeof jobTypes)[number]>(
			"inference.sdxl.txt2img.v1"
		);

		const config = ref<Record<string, any>>({});
		const imageInputs = ref<File[]>([]);

		const selectedProperties = computed(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			// @ts-ignore
			const flatternProperties = (schema: any) => {
				if ("properties" in schema)
					return flatternProperties(schema.properties);

				if ("allOf" in schema)
					return Object.assign(
						{},
						...schema.allOf.map(flatternProperties).flat()
					);

				if ("oneOf" in schema)
					return flatternProperties(schema.oneOf[0]);

				return schema;
			};

			if (processor === undefined) return undefined;

			const schema = flatternProperties(processor.schema);

			console.log(schema);

			const _config = schema?.config || schema?.properties?.config || {};

			return flatternProperties(_config);
		});

		const defaultConfig = computed<Record<string, any>>(() => {
			const defaultConfig = {};

			for (const key in selectedProperties.value) {
				const defaultValue =
					selectedProperties.value[key]?.default ||
					selectedProperties.value[key]?.examples?.[0] ||
					undefined;

				// @ts-ignore
				if (defaultValue !== undefined && key !== "seed") {
					// don't set a default value for seeds
					// @ts-ignore
					defaultConfig[key] = defaultValue;
				}
			}

			return defaultConfig;
		});

		watch(defaultConfig, () => {
			config.value = {};
			imageInputs.value = [];

			console.log(defaultConfig.value);

			for (const key in defaultConfig.value) {
				if (typeof defaultConfig.value[key] === "boolean") {
					config.value[key] = defaultConfig.value[key];
				}
			}

			console.log(config.value);
		});

		const payload = computed(() => {
			return {
				type: selectedJobType.value,
				config: {
					...defaultConfig.value,
					...config.value
				}
			};
		});

		type ResponseType = "image" | "video";

		const token = ref(localStorage.getItem("v2-token") || "");
		const remember = ref<boolean>(token.value !== "");
		const image = ref<string | undefined>(undefined);
		const imageFile = ref<File | undefined>(undefined);
		const responseType = ref<ResponseType>("image");
		const isGenerating = ref<boolean>(false);
		const generationTime = ref<number | undefined>(undefined);

		const run = async () => {
			console.log({ ...payload.value });

			const startTime = Date.now();

			isGenerating.value = true;

			if (remember.value) {
				localStorage.setItem("v2-token", token.value);
			} else {
				localStorage.removeItem("v2-token");
			}

			const formData = new FormData();

			const jobBlob = new Blob([JSON.stringify(payload.value)], {
				type: "application/json"
			});

			if (imageInputs.value[0]) {
				formData.append("input", imageInputs.value[0], "image.jpg");
			}

			formData.append("job", jobBlob, "job.json");

			const response = await fetch(`${inferenceUrl}/v2/job`, {
				method: "POST",
				headers: {
					Accept: "multipart/form-data",
					Authorization: `Bearer ${token.value}`
				},
				body: formData
			});

			const _generationTime = Date.now() - startTime;

			if (response.status === 429) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				run();
			}

			const body = await response.formData();

			for (const [key, value] of body.entries()) {
				console.log("body", { key, value });
			}

			// get image data
			const imageBlob = body.get("output") as File;

			responseType.value = imageBlob.type.includes("image")
				? "image"
				: "video";

			const imageUrl = URL.createObjectURL(imageBlob);

			image.value = imageUrl;

			imageFile.value = new File(
				[imageBlob],
				selectedJobType.value.includes("vid")
					? "video.mp4"
					: "image.jpg",
				{
					type: imageBlob.type
				}
			);

			console.log("imageFile", imageFile.value);

			generationTime.value = _generationTime;
			isGenerating.value = false;
		};

		const copyCurl = () => {
			const curlCommand = `curl --request POST \\
--url https://inference.prodia.com/v2/job \\
     --header 'accept: image/jpeg' \\
     --header 'content-type: application/json' \\
	 --header 'authorization: Bearer ${token.value}' \\
     --data '
${JSON.stringify(payload.value, null, "\t")}
' \\
--output image.jpg
`;

			navigator.clipboard.writeText(curlCommand);
		};

		const handleFileUpload = (event: Event) => {
			const target = event.target as HTMLInputElement;

			if (target.files && target.files.length > 0) {
				const file = target.files[0];

				if (file) {
					imageInputs.value[0] = file;
				}
			}
		};

		const selectedTab = ref<"explore" | "code">("explore");

		const javascriptCode = computed(() => {
			return `import fs from "node:fs/promises";
import { createProdia } from "prodia/v2";

const prodia = createProdia({
	token: process.env.PRODIA_TOKEN${
		selectedJobType.value.includes("mochi")
			? `,
	maxRetries: Infinity`
			: ""
	}
});

(async () => {
	const job = await prodia.job(${JSON.stringify(payload.value, null, "\t")
		.split("\n")
		.map((l, i) => (i === 0 ? l : "        " + l))
		.join("\n")}, {
		accept: ${JSON.stringify(
			selectedJobType.value.includes("vid") ? "video/mp4" : "image/jpeg"
		)}
	});

	const image = await job.arrayBuffer();

	await fs.writeFile(${JSON.stringify(
		selectedJobType.value.includes("vid") ? "output.mp4" : "output.jpg"
	)}, new Uint8Array(image));
})();`;
		});

		// Add computed property for current theme
		const isDarkMode = computed(
			() => document.body.getAttribute("data-mode") === "dark"
		);

		const share = async () => {
			if (imageFile.value === undefined) return;

			if (!navigator.canShare({ files: [imageFile.value] })) return;

			const shareText = `${
				config.value.prompt ? `“${config.value.prompt}” ` : ""
			}https://app.prodia.com/explorer`;

			await navigator.share({
				files: [imageFile.value],
				title: "Generated with Prodia",
				text: shareText
			});
		};

		return {
			selectedTab,
			plan,
			jobTypes,
			selectedJobType,
			selectedProperties,
			config,
			payload,
			token,
			remember,

			image,
			run,
			isGenerating,
			generationTime,
			copyCurl,
			imageInputs,
			handleFileUpload,
			responseType,
			javascriptCode,
			isDarkMode,
			share
		};
	}
};
